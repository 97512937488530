<template>
  <div class="myPopover">
    <div class="toggleBox">
      <div class="toggle" @click='toggleFn'>
        <slot name="toggle" class='toggleClass'></slot>
      </div>
      <transition name="fade">
        <div v-show='isHidden' class="menuBox" :style='"top:"+toggleHeight+"px"'>
          <div class="iconfontArrow"></div>
          <slot></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {defineComponent,onMounted,onUnmounted,ref, nextTick,watch } from 'vue';
export default defineComponent({
  name:"myPopover",
  setup(props,context){
    let toggleHeight = ref(0);
    let isHidden = ref(false);
    const pc = context.slots.toggle()[0].props.class;
    const toggleFn = ()=>{
      isHidden.value = !isHidden.value;
    };
    const toggleClick = (e)=>{
      if(e.target.className === pc || e.target.parentNode.className === pc){
      }else{
        if(isHidden.value){
          isHidden.value = !isHidden.value
        }
      }
    };
    let soltName = ref('');
    onMounted(()=>{
      document.addEventListener('click',toggleClick,true);
      soltName.value = context.slots.toggle()[0].el.className;
      const dom = document.getElementsByClassName(soltName.value)[0];
      toggleHeight.value = dom.offsetHeight + 16;
    });
    onUnmounted(()=>{
      document.removeEventListener('click',toggleClick,true)
    })
    return {
      isHidden,
      toggleHeight,
      toggleFn
    }
  }
})
</script>

<style lang="scss" scoped>
.myPopover{
  box-sizing: border-box;
  position: relative;
  .toggleBox{
    position: relative;
    .toggle{
      padding:5px;
    }
    .menuBox{
      background:#fff;
      box-shadow:0 2px 12px 0 rgba(0, 0, 0, 0.1);
      border:1px solid #E4E7ED;
      border-radius: 4px;
      position: absolute;
      left:50%;
      transform: translateX(-50%);
      z-index: 3000;
      .iconfontArrow{
        position:absolute;
        left:50%;
        top:-6px;
        transform: translateX(-50%);
        width:0;
        height:0;
        border-width:0 6px 6px;
        border-style:solid;
        border-color:transparent transparent #E4E7ED;
        &:after{
          content: "";
          position: absolute;
          top: 1px;
          left: -8px;
          border-width: 0 8px 8px;
          border-style: solid;
          border-color: transparent transparent #fff;
        }
      }
    }
  }
}
</style>