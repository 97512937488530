<template>
  <div class="CaseMainFiles" v-loading="upLoadLoading">
    <div class="top">
      <div class="serchBox">
        <el-input
          size='small'
          class='fileSerchInput'
          placeholder="输入文件名，按回车确认"
          suffix-icon="iconfont iconfangdajing"
          v-model="keyword"
          clearable
          @keydown.enter="serchData">
        </el-input>
      </div>
      <div class="addBox">
        <div class="downLoad" 
        @click="downPackage"
        v-loading='packageLoading'
         v-if='packageInfoData.packageStatus!==1'>
          <div class="iconfont iconxiazai"></div>
          <div class="text">下载归档文件</div>
        </div>
        <div class="guidang" @click='filing'>归档</div>
        <div class="addBtn">
          <myPopover>
            <template v-slot:toggle>
              <div class="add"><div class="iconfont iconicon-test"></div></div>
            </template>
            <div class="list">
              <div @click='createList'>新建目录</div>
              <div>
                <el-upload class='uploadBtn'
                  ref="upload"
                  action="#"
                  :show-file-list='false'
                  :http-request="upLoadFileFn">
                  上传文件
                </el-upload>
              </div>
              <div @click='createTemplate'>模板目录</div>
            </div>
          </myPopover>
        </div>
      </div>
    </div>
    <div class="table" v-loading="loadingFileList">
      <div class="breadCrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item 
          v-for='(item,index) in fileRoute' 
          :key='index'
          @click='menuClick(item,index)'>
            {{item.name}}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="tableForm">
        <el-table
            :data="tableData"
            style="width: 100%"
            :default-sort = "{prop: 'date', order: 'descending'}"
            >
            <el-table-column
              label="名称"
              width='500'
             >
              <template #default="scope">
                <div class='fileNameList'>
                  <svg class="icon fileIcon" aria-hidden="true">
                    <use :xlink:href="'#'+iconFontFilter(scope.row)"></use>
                  </svg>
                  <span class='fileNameTitle' @click='enterFile(scope.row)'>{{ scope.row.dossierName }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="changeDate"
              label="更新时间"
              sortable
            >
            </el-table-column>
            <el-table-column
              prop="fileSize"
              label="文件大小"
              sortable
            >
            </el-table-column>
            <el-table-column width='100' align='center'>
              <template #default="scope">
                <el-dropdown trigger="click" @command='commandMenu($event,scope.row)'>
                  <div class="iconfont icongengduo"></div>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item command='1'>重命名</el-dropdown-item>
                      <el-dropdown-item command='2'>下载</el-dropdown-item>
                      <el-dropdown-item command='3'>复制</el-dropdown-item>
                      <el-dropdown-item command='4'>移动</el-dropdown-item>
                      <el-dropdown-item command='5'>删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
      </div>
    </div>
    <!-- 模板目录 -->
    <myDialog 
    :isShow='isShow'
    :closeDialog='closeDialog'>
      <div class="treeBox" 
        v-loading='moveLoading'>
        <el-tree
        ref="templateTree"
          :props="defaultPropsMove"
          :data="treeComplateData"
          show-checkbox
          node-key="dossierTemplateId"
          auto-expand-parent
          default-expand-all
          :default-checked-keys='defaultCheckedKeyFn()'
          >
          <template #default="{data}">
            <div class='treeItem'>
              <span class="iconfont" style="marginRight:10px">
                <svg class="icon fileIcon" aria-hidden="true">
                  <use :xlink:href="'#'+iconFontFilter(data)"></use>
                </svg>
              </span>
              <span class='text'>{{ data.dossierName }}</span>
            </div>
          </template>
        </el-tree>
      </div>
      <div class="filesTreefooterBtn">
        <el-button @click='closeDialog' type="primary" plain style='width:70px' size='small'>取 消</el-button>
        <el-button @click='getDefaultTemplate' type="primary" style='width:70px' size='small'>确 定</el-button>
      </div>
    </myDialog>
    <!-- 移动 -->
    <myDialog 
    :isShow='isShowMove'
    :closeDialog='closeDialogMove'>
      <div class="treeBox" v-loading='moveLoading'>
        <el-tree
        ref="filesTreeMove"
        class='filesTree'
        :data="moveTreeData"
        node-key="id"
        default-expand-all
        :props="defaultPropsMove"
        draggable
        @node-drag-start="handleDragStart"
        @node-drop="handleDrop">
          <template #default="{data}">
            <div class='treeItem'>
              <span class="iconfont">
                <svg class="icon fileIcon" aria-hidden="true">
                  <use :xlink:href="'#'+iconFontFilter(data)"></use>
                </svg>
              </span>
              <span class='text'>{{data.dossierName}}</span>
            </div>
          </template>
      </el-tree>
      </div>
    </myDialog>
    <!-- 归档 -->
    <myDialog 
    :isShow='isShowGuidang'
    :closeDialog='closeDialogGuidang'>
      <div class="treeBox" 
        v-loading='moveLoading'>
        <el-tree
        ref="guidangTree"
          :props="defaultPropsMove"
          :data="guidangDate"
          show-checkbox
          node-key="dossierId"
          auto-expand-parent
          default-expand-all
          :default-expanded-keys="[]"
          :default-checked-keys="[]">
          <template #default="{data}">
            <div class='treeItem'>
              <span class="iconfont" style="marginRight:10px">
                <svg class="icon fileIcon" aria-hidden="true">
                  <use :xlink:href="'#'+iconFontFilter(data)"></use>
                </svg>
              </span>
              <span class='text'>{{ data.dossierName }}</span>
            </div>
          </template>
        </el-tree>
      </div>
      <div class="filesTreefooterBtn">
        <el-button @click='closeDialogGuidang' type="primary" plain style='width:70px' size='small'>取 消</el-button>
        <el-button @click='getGuiDangKeys' type="primary" style='width:70px' size='small'>确 定</el-button>
      </div>
    </myDialog>
    <div class="previewB">
      <el-image
      ref='imagePreview' 
      v-if='isPreviewFile'
      style="width: 100px; height: 100px"
      :src="previewFileUrl"
      hide-on-click-modal
      :preview-src-list="previewFileList">
      </el-image>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import myDialog from '@/components/myDialog.vue';
import myPopover from '@/components/myPopover.vue';
import {addCatalogue,dossierTree,renameDossier,addDossier,uploadFile,
delDossier,moveDossier,filingDossier,getFillTree,getTree,generate,packageInfo,
} from '@/api';
export default defineComponent({
  name:"CaseMainFiles",
  components:{
    myPopover,
    myDialog
  },
  created(){
    this.getdossierTree();
    this.packageInfoApi()
  },
  watch:{
    '$route'(to,from){
      if(to.path.includes('/case/main/files/')){
        this.getdossierTree()
      }
    }
  },
  data(){
    return {
      keyword:'',
      packageLoading:false,
      packageInfoData:'',
      upLoadLoading:false,
      treeComplateData:[],
      getAllMenuParentId:'',
      handleDragStartId:'',
      moveLoading:false,
      moveTreeData:[],
      loadingFileList:false,
      parentId:'',
      isPreviewFile:false,
      previewFileUrl:'',
      previewFileList:[],
      menuClickBtn:true,
      isShowMove:false,
      // tableData: [{
      //   name: '证据材料',
      //   update: '2020-01-12 12:22',
      //   fileSize: '12MB',
      //   type:"file",
      //   dossierId: "1407183786851590145"
      // },{
      //   name: '传票',
      //   update: '2020-01-12 12:22',
      //   fileSize: '12KB',
      //   type:'img',
      //   dossierId: "1407183786851590145"
      // },{
      //   name: '发票清单',
      //   update: '2020-01-12 12:22',
      //   fileSize: '12MB',
      //   type:'exec',
      //   dossierId: "1407183786851590145"
      // },{
      //   name: '合同',
      //   update: '2020-01-12 12:22',
      //   fileSize: '12MB',
      //   type:"pdf",
      //   dossierId: "1407183786851590145"
      // },{
      //   name: '演讲稿',
      //   update: '2020-01-12 12:22',
      //   fileSize: '12MB',
      //   type:"word",
      //   dossierId: "1407183786851590145"
      // }],
      tableData:[],
      fileRoute:[{
        name:'全部'
      }],
      isShow:false,
      // treeData: [{
      //   id: 1,
      //   label: '收案审批表',
      //   type:'file'
      // }, {
      //   id: 2,
      //   label: '收费凭证',
      //   type:'file'
      // }, {
      //   id: 3,
      //   label: '委托材料',
      //   type:'file',
      //   children: [{
      //     id: 8,
      //     label: '委托书',
      //     type:'file',
      //     children: [{
      //       id: 12,
      //       label: '委托代理协议',
      //       type:'file'
      //     }, {
      //       id: 13,
      //       label: '授权委托书',
      //       type:'file'
      //     }]
      //   }, {
      //     id: 9,
      //     label: '风险告知书',
      //     type:'file'
      //   }]
      // }, {
      //   id: 4,
      //   label: '阅卷笔录',
      //   type:'file'
      // },{
      //   id: 5,
      //   label: '当事人谈话笔录',
      //   type:'file'
      // },{
      //   id: 6,
      //   label: '调查资料',
      //   type:'file',
      //   children: [{
      //       id: 10,
      //       label: '证人证言',
      //       type:'file'
      //     }, {
      //       id: 11,
      //       label: '书证',
      //       type:'file'
      //     }]
      // },{
      //   id: 7,
      //   label: '法院裁定书',
      //   type:'file'
      // }],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      defaultPropsMove: {
        children: 'children',
        label: 'dossierName'
      },
      isShowGuidang:false,
      guidangDate:[]
    }
  },
  computed:{
    defaultCheckedKeys(){
      let arr = [];
      for(let i=1;i<=13;i++){
        arr.push(i)
      }
      return arr
    }
  },
  methods: {
    
    
    async getDefaultTemplate(){
      let templateIdList = this.$refs['templateTree'].getCheckedKeys();
      let res = await generate({
       relId:this.$route.params.caseId,
       relType:2,
       templateIdList
      });
      if(res&&res.code==200){
        this.closeDialog();
        this.getdossierTree();
        this.$message({
          type:"success",
          message:'创建成功'
        })
      }
    },
    createTemplate(){
      this.isShow = true;
      this.getTreeApi()
    },
    // 获取模板
    async getTreeApi(){
      let res = await getTree({
        relType:2
      });
      if(res&&res.code==200){
        this.treeComplateData = res.data.treeList;
      };
    },
    defaultCheckedKeyFn(){
      let arr = [];
      this.treeComplateData.forEach(item=>{
        arr.push(item.dossierTemplateId)
      });
      return arr
    },
    // 点击归档按钮
    async filing(){
      this.isShowGuidang = true;
      let res = await getFillTree({
        relId:this.$route.params.caseId,
        relType:2
      });
      if(res&&res.code==200){
        this.guidangDate = res.data.treeList
      };
    },
    getGuiDangKeys() {
      let dossierIdList = this.$refs['guidangTree'].getCheckedKeys();
      this.filingDossierApi({
        dossierIdList,
        relId:this.$route.params.caseId,
        relType:2
      });
      this.closeDialogGuidang()
    },
    closeDialogGuidang(){
      this.isShowGuidang = false;
    },
    // 归档Api
    async filingDossierApi(data){
      let res = await filingDossier(data);
      if(res&&res.code==200){
        this.packageInfoApi();
        this.$message({
          type:"success",
          message:"归档成功"
        })
      }
    },
    // 移动文件
    async moveDossierApi(data){
      this.moveLoading = true;
      let res = await moveDossier(data);
      if(res&&res.code==200){
        this.$message({
          type:"success",
          message:"成功"
        })
      };
      this.moveLoading = false;
    },
    upLoadFileFn(file){
      this.createFile(file.file)
    },
    async createFile(file){
      this.upLoadLoading = true;
      let formData = new FormData()
      formData.append('file',file)
      formData.append("filepath",'caseFiles')
      let res = await uploadFile(formData);
      if(res&&res.code==200){
        this.addDossierApi({
          relType:2,
          relId:this.$route.params.caseId,
          parentId:this.getAllMenuParentId,
          fileId:res.data.fileId
        })
      };
      this.upLoadLoading = false;
    },
    // 新建卷宗
    async addDossierApi(data){
      let res = await addDossier(data);
      if(res&&res.code==200){
        // 更新数据
        this.getdossierTree()
        this.$message({
          type:'success',
          message:'成功'
        })
      }
    },
    // 删除卷宗
    async delDossierApi(data){
      let res = await delDossier(data);
      if(res&&res.code==200){
        // 更新数据
        this.getdossierTree()
        this.$message(
          {
            type:"success",
            message:"删除成功"
          }
        )
      }
    },
    handleDragStart(node, ev) {
      console.log('drag start', node);
      this.handleDragStartId = node.data.dossierId;
    },
    async handleDrop(draggingNode, dropNode, dropType, ev) {
      console.log('tree drop: ', dropNode.data, dropType);
      await this.moveDossierApi({
        dossierId:this.handleDragStartId,
        moveDossierId:dropNode.data.dossierId
      });
      this.handleDragStartId = '';
    },
    closeDialogMove(){
      this.isShowMove = false;
      // 更新数据
      this.getdossierTree();
    },
    async getdossierTree(data){
      this.loadingFileList = true;
      let query;
      if(data){
        query = data
      }else{
        query = {
          relId:this.$route.params.caseId,
          relType:2,
          parentId:this.getAllMenuParentId,
          keyword:this.keyword
        }
      }
      let res = await dossierTree(query);
      this.loadingFileList = false;
      if(res&&res.code==200){
        this.tableData = res.data;
        this.moveTreeData = res.data
      };
      console.log("moveTreeData:",this.moveTreeData)
    },
    downLoadFn(url){
      if(url){
        let a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.click();
      }else{
        return
      }
    },
    async menuClick(item,index){
      if(!this.menuClickBtn){
        return;
      };
      this.menuClickBtn = false;
      if(this.fileRoute.length === 1 && this.fileRoute[0].name==='全部' && index===0){
        return;
      };
      let allLength = this.fileRoute.length;
      if(index<allLength-1){
        let deletIdx = allLength-index;
        this.fileRoute.splice(index+1,deletIdx)
      };
      this.getAllMenuParentId = item.dossierId;
      console.log(item,index);
      await this.getdossierTree();
      this.menuClickBtn = true;
    },
    async createList(){
      this.$prompt('请输入目录名称', '提示', {
        confirmButtonText: '创建',
        cancelButtonText: '取消',
        inputValidator:this.myInputValidator,
      }).then(async ({ value }) => {
        await this.createApi({
          catalogueName:value,
          parentId:this.getAllMenuParentId,
          relId:this.$route.params.caseId,
          relType:2
        })
      }).catch((e) => {
        this.$message({
          type: 'info',
          message: '取消创建'
        });
      });
    },
    async createApi(data){
      let res = await addCatalogue(data);
      if(res&&res.code==200){
        // 更新数据
        this.getdossierTree()
        this.$message({
          type: 'success',
          message: '创建成功'
        });
      };
    },
    myInputValidator(v){
      if(v){
        v=v.replace(/\s+/g,""); 
        if(v!==''&&v!==null&&v!==undefined){
          return true
        }
      }
      return '名称不能为空'
    },
    async commandMenu(val,row){
      console.log('commandMenu:',val,row)
      switch(val){
        case '1':
          // 重命名
          this.$prompt('请输入新名称', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputValue:row.dossierName,
            inputValidator:this.myInputValidator,
          }).then(async ({ value }) => {
            this.renameDossierApi({
              dossierId:row.dossierId,
              originalFileName:value
            })
          }).catch((e) => {
            this.$message({
              type: 'info',
              message: '取消'
            });
          });
          break;
        // 下载
        case '2':
          this.downLoadFn(row.file?row.file.ossUrl:'')
          break;
        // 复制
        case '3':
          break;
        // 移动
        case '4':
          this.isShowMove = true;
          await this.getdossierTree();
          break;
          // 删除
        case '5':
          this.delDossierApi({
            dossierId:row.dossierId
          })
          break;
      }
    },
    async downPackage(){
      await this.packageInfoApi();
      //  1 未打包过 2 待打包 3 已经打包完成
      // packageStatus为 1 不展示任何下载内容 
      // 4 可以下载
      // 其他状态都是灰色不可下载
      switch(this.packageInfoData.packageStatus){
        case 1 :
          this.$message('未打包过')
          break;
        case 2 :
          this.$message('打包中，请稍后再试')
          break;
        case 3 :
          this.downLoadFn(this.packageInfoData.url);
          break;
        // case 1 :
        //   this.$message('未打包过')
        //   break;
        // case 2 :
        //   this.$message('待打包，请稍后再试')
        //   break;
        // case 3 :
        //   this.$message('正在打包中，请稍后再试')
        //   break;
        // case 4 :
        //   this.downLoadFn(this.packageInfoData.url);
        //   break;
        // case 5 :
        //   this.$message('失败，请稍后再试')
        //   break;
      }
    },
    serchData(){
      this.getdossierTree()
    },
    async packageInfoApi(){
      this.packageLoading = true;
      let res = await packageInfo({
        relId:this.$route.params.caseId,
        relType:2
      });
      if(res&&res.code==200){
        this.packageInfoData = res.data
      };
      this.packageLoading = false;
    },
    async renameDossierApi(data){
      let query;
      if(data){
        query = data
      }else{
        query = {
          relId:this.$route.params.caseId,
          relType:2
        }
      }
      let res = await renameDossier(query);
      if(res&&res.code==200){
        this.getdossierTree()
      }
      console.log('renameDossierApi:',res)
    },
    iconFontFilter(scope){
      let type;
      let fileType;
      if(scope.file&&scope.file.suffix){
        type = scope.file.suffix
      }else{
        type = '';
      };
      const imgArr = ['png','jpg','jpeg'];
      const fileArr = ['doc','docx','pdf','xls','xlsx'];
      if(type){
        fileType = type.toLowerCase();
        if(imgArr.includes(fileType)){
          fileType = 'img'
        }else if(fileArr.includes(fileType)){
          
        }else{
          fileType = ''
        }
      }else{
        fileType = 'file'
      };
      switch(fileType){
        case 'file':
        return 'iconFOLDER';
        case 'img':
        return 'iconPICTURE';
        case 'xls':
        return 'iconEXCEL';
        case 'xlsx':
        return 'iconEXCEL';
        case 'pdf':
        return 'iconPDF';
        case 'doc':
        return 'iconWORD';
        case 'docx':
        return 'iconWORD';
        case 'ppt':
        return 'iconPPT';
        case 'text':
        return 'iconTEXT';
        case 'rar':
        return 'iconRAR';
        case 'audio':
        return 'iconAUDIO';
        case 'video':
        return 'iconVIDEO';
        default:
        return 'iconUNKNOW'
      }
    },
    previewFile(file){
      console.log(file)
      // 图片
      const imgArr = ['png','jpg','jpeg'];
      // 文档
      const fileArr = ['doc','docx','pdf','xls','xlsx'];
      if(imgArr.includes(file.suffix)){
        this.isPreviewFile = true;
        this.previewFileUrl = file.ossUrl;
        this.previewFileList.push(file.ossUrl);
        this.$nextTick(()=>{
          let dom = this.$refs['imagePreview'];
          dom.clickHandler()
        })
      };
      if(fileArr.includes(file.suffix)){
        let xurl = "https://view.xdocin.com/xdoc?_xdoc=";
        xurl += encodeURIComponent(file.ossUrl);
        window.open(xurl,"_blank")
      }
    },
    enterFile(val){
      if(!val.file){
        // 请求对应文件夹下的文件数据
        this.fileRoute.push({
          name:val.dossierName,
          dossierId:val.dossierId
        });
        this.getAllMenuParentId = val.dossierId
        // 获取该文件夹下的数据
        this.getdossierTree();
      }else{
        this.previewFile(val.file)
        // 预览该类型文件
        return
      }
    },
    
    closeDialog(){
      this.isShow = false
    },
    getFileTree(){
      console.log(this.$refs.filesTree.getCheckedKeys());
      this.closeDialog()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/comman.scss';
.CaseMainFiles{
  padding: 20px 0;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    position: relative;
  .top{
    padding:0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .serchBox{
      .fileSerchInput{
        ::v-deep{
          .el-input__inner{
            border-radius:30px
          }
          .el-input__suffix{
            right:10px
          }
        }
      }
    }
    .addBox{
      display: flex;
      align-items: center;
      .downLoad{
        display: flex;
        align-items: center;
        color:#555;
        &:hover{
          cursor: pointer;
          color:$main-color
        }
        .iconfont{
          font-size: 22px;
        }
        .text{
          font-size: 14px;
          margin-left:5px;
        }
      }
      .guidang{
        width: 90px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 30px;
        border: 1px solid #ddd;
        font-size: 14px;
        color:#555;
        margin:0 15px;
        &:hover{
          cursor: pointer;
          color:$main-color;
          border-color:$main-color;
        }
      }
      .addBtn{
        .add{
          width: 90px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 30px;
          border: 1px solid $main-color;
          font-size: 18px;
          font-weight: 400;
          color:#fff;
          background:$main-color;
          &:hover{
            cursor: pointer;
          }
        }
        .list{
          width: 78px;
          .uploadBtn{
            width:100%;
            box-sizing: border-box;
            .el-upload{
              width:100%;
              box-sizing: border-box;
              padding:10px 0
            }
          }
          div{
            padding: 10px 0;
            width: 100%;
            box-sizing: border-box;
            text-align: center;
            font-size: 14px;
            color: #555;
            padding: 10px 0;
            &:hover{
              cursor: pointer;
              color:$main-color;
            }
          }
        }
      }
    }
  }
  .table{
    padding:30px;
    .breadCrumb{
      padding-left:10px;
      margin-bottom:20px;
      .el-breadcrumb{
        .el-breadcrumb__item{
          &:hover{
            cursor:pointer;
          }
          .el-breadcrumb__inner{
            &:hover{
              cursor:pointer;
            }
          }
          
        }
      }
    }
    .tableForm{
      border-top:1px solid #eee;
      .el-dropdown{
        &:hover{
          cursor: pointer;
        }
      }
      .fileNameList{
        display: flex;
        align-items: center;
        .fileIcon{
          width:20px;
          height:20px;
        }
        .fileNameTitle{
          font-size: 14px;
          color:#555;
          margin-left:10px;
          &:hover{
            cursor: pointer;
            color:$main-color
          }
        }
      }
      
    }
  }
}
.previewB{
  position: absolute;
  left:9999px;
  top:9999px;
}

</style>